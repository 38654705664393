export default (state, data) => {
	console.info('🦑 SET_NFT_DATA', data);

	if (state.nfts === null) {
		state.nfts = {};
	}

	if (data.enabled) {
		state.nfts[data.key] = data;
	} else {
		console.info('Skipping disabled NFT: ', data);
	}
};
