export default {
	ERROR: 0,
	AUTH_STAFF: 1,
	INITIAL_LOAD: 2,
	SIGN_IN: 3,
	AUTH_MIXED_OWNERSHIP: 4,
	AUTH_MIXED_OWNERSHIP_PHYSICAL: 5,
	AUTH_EDIT: 6,
	AUTH_EDIT_SUCCESS: 7,
};
