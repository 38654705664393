<template>
	<div class="overlay overlay--error">
		<div class="bg"></div>

		<div class="box">
			<div class="content">
				<h1 class="heading">{{ heading }}</h1>
				<div v-if="message" class="message">{{ message }}</div>

				<a class="button--close" v-if="closeToRefresh" @click.prevent="close" href="#">
					<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m1.50015.0864258-1.4142125 1.4142142 10.5000625 10.50026-10.5000625 10.4999 1.4142125 1.4142 10.50005-10.4998 10.4996 10.4997 1.4142-1.4142-10.4996-10.4997 10.5001-10.49982-1.4143-1.4142102-10.5 10.4997302z" fill="#fff" fill-rule="evenodd"/></svg>
					<span class="vh">Close</span>
				</a>
			</div>

			<div v-if="ctaLabel">
				<ArrowButton
					@click="$emit('cta-click')"
					:label="ctaLabel"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton';

export default {
	name: 'ErrorOverlay',
	components: {
		ArrowButton,
	},
	methods: {
		signOut() {
			this.$store.dispatch('signOut');
		},
		close() {
			window.location = '/';
		},
	},
	props: {
		closeToRefresh: {
			type: Boolean,
			default: false,
		},
		heading: String,
		message: String,
		ctaLabel: String,
	},
};
</script>

<style lang="scss">
.overlay--error {
	.bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.65);
		z-index: 1000;
	}

	.button--close {
		position: absolute;
		top: 16px;
		right: 16px;
		width: 40px;
		height: 40px;

		svg	 {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;

			path {
				fill: black;
			}
		}
	}

	.box {
		z-index: 1100;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 100%;
		max-width: 640px;
		transform: translate(-50%, -50%);

		.content {
			padding: 16px;
			background: #FDBE40;
			min-height: 300px;
		}

		.heading {
			font-style: normal;
			font-weight: 500;
			font-size: 60px;
			line-height: 56px;
			letter-spacing: 0.005em;
			text-transform: uppercase;
			margin: 0;
		}

		.message {
			margin: 40px 0 0;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 32px;
		}
	}
}
</style>
