export default (state, getters) => {
	// Does the user own any NFTs?
	const anyOwned = state.claims ? Object.values(state.claims).length > 0 : false;

	// Have any of the NFTs been claimed previously by another user?
	const mixedOwnership = getters.claimsByAnotherUser.length > 0;

	// Are any of the NFTs eligible for physical redemption?
	const anyPhysical = getters.claimsByUser.some(claim => {
		const nft = Object.values(state.nfts).find(nft => nft.contract_address === claim.contract_address && nft.item_id === claim.item_id);

		return nft ? nft.component === 'physical' : false;
	});

	// Are any of the NFTs owned or unclaimed (and can be claimed) by the user
	const anyClaimable = getters.claimsByUser.length > 0;

	return {
		anyOwned,
		mixedOwnership,
		anyPhysical,
		anyClaimable,
	};
};
