<template>
	<div class="control">
		<input
			:id="`${id}`"
			type="checkbox"
			class="vh"
			v-model="intermediateValue"
			v-on:input="$emit('input', $event.target.checked)">
		<label :for="`${id}`">
			<div class="display">
				<svg fill="none" height="13" viewBox="0 0 17 13" width="17" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m7.07101 11.3138h.00006l-1.41422 1.4142-5.65685-5.65681 1.41421-1.41421 4.24258 4.24258 9.89951-9.89956 1.4143 1.41421z" fill="#fff" fill-rule="evenodd"/></svg>
			</div>
			<div class="control__label">
				{{ label }}
			</div>
		</label>
	</div>
</template>

<script>
import { generatePushID } from '../Utils';

export default {
	name: 'LargeCheckbox',
	props: {
		label: String,
		value: Boolean,
	},
	data() {
		return {
			id: `field-${generatePushID()}`,
			intermediateValue: this.value,
		};
	},
};
</script>

<style lang="scss" scoped>
.control {
	input + label {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		gap: 16px;
		align-items: center;

		.display {
			position: relative;
			flex: 0 0 48px;
			width: 48px;
			height: 48px;
			border: solid 1px black;
			background: transparent;

			svg {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
			}
		}
	}

	input:checked + label {
		.display {
			background: black;

			svg	{
				opacity: 1
			}
		}
	}
}

.control__label {
	max-width: 600px;
	flex: 1 1 auto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
}
</style>
