import { getFirestore, getDoc, doc } from '@firebase/firestore/lite';

export default async ({ state, commit }) => {
	// Fetch user data
	const firestore = getFirestore();
	const docRef = doc(firestore, 'vbk_wallet_user_data', state.user.walletAddress);
	const snapshot = await getDoc(docRef);

	commit('SET_USER_DATA', snapshot.exists() ? snapshot.data() : {});
};
