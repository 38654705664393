<template>
	<video autoplay loop playsinline muted  poster="/assets/logo.png" width="400" height="320" preload="auto">
		<source src="/assets/logo.mp4" type="video/mp4">
		<source src="/assets/logo.webm" type="video/webm">
		<img src="/assets/logo.png" alt="">
	</video>
</template>

<script>
export default {
	name: 'Logo',
};
</script>
