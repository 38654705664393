const fields = {
	size: 'Choose size',
	firstName: 'First name',
	lastName: 'Last name',
	address1: 'Address',
	address2: 'Address Line 2',
	city: 'City',
	country: 'Country',
	postcode: 'Postcode',
	phone: 'Phone',
	email: 'Email',
	consent: "I want advance access to all your latest gear – sign me up to your emails (it's easy to unsubscribe if you ever want to)",
};

export default {
	signIn: {
		body: "We just need a couple of details from you to activate your utility benefits and ship your jacket if you've bought a dark or light edition. First step is to validate your purchase by connecting your MetaMask wallet.",
		cta: 'Let’s go',
		contact: "We're a startup run by a small and insanely committed team, so if you've got any questions just email us on:",
		emailSubject: 'Metastore Wearables',
		email: 'team@vollebak.com',
	},
	edit: {
		physical: {
			heading: 'Your details',
			subheading: 'Your shipping details',
			notice: 'Let us know which size you’d like and where we should send your jacket. Don’t worry if the size isn’t right, we have free returns and exchanges.',
			fields,
		},
		utilityOnly: {
			heading: 'Utility',
			subheading: 'Your details',
			notice: null,
			fields,
		},
	},
	mixedOwnership: {
		physical: {
			heading: 'Utility access',
			body: 'The physical jacket has already been claimed, but as the owner of the limited edition wearable you still get access to exclusive utilities. Register your details to access.',
		},
		utilityOnly: {
			heading: 'Utility access',
			body: 'The wearable has already been claimed, but as the current owner, you still get access to exclusive utilities. Register your details to access.',
		},
	},
	success: {
		physical: {
			heading: 'Mission complete',
			body: "Thank you for being a pioneer with our first metastore. We're getting your jacket ready to ship and will email you again with a tracking number when it's on its way to you. Your utility benefits will be activated very soon and we’ll be in touch with how to use.",
			contact: "We're a startup run by a small and insanely committed team, so if you've got any questions just email us on:",
			emailSubject: 'Metastore Wearables',
			email: 'team@vollebak.com',
		},
		utility: {
			heading: 'Mission complete',
			body: 'Thank you for being a pioneer with our first metastore. Your utility benefits will be activated very soon and we’ll be in touch with how to use.',
			contact: "We're a startup run by a small and insanely committed team, so if you've got any questions just email us on:",
			emailSubject: 'Metastore Wearables',
			email: 'team@vollebak.com',
		},
	},
};
