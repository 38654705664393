<template>
	<div id="app" :class="classes">
		<LoadingScreen v-if="screen === ScreenStates.INITIAL_LOAD" />
		<SignInScreen v-if="screen === ScreenStates.SIGN_IN" />
		<EditScreen v-if="screen === ScreenStates.AUTH_EDIT" />
		<SuccessScreen v-if="screen === ScreenStates.AUTH_EDIT_SUCCESS" />
		<StaffScreen v-if="screen === ScreenStates.AUTH_STAFF" />
		<NoticeScreen
			v-if="screen === ScreenStates.AUTH_MIXED_OWNERSHIP_PHYSICAL"
			:heading="locale.mixedOwnership.physical.heading"
			:body="locale.mixedOwnership.physical.body"
		/>
		<NoticeScreen
			v-if="screen === ScreenStates.AUTH_MIXED_OWNERSHIP"
			:heading="locale.mixedOwnership.utilityOnly.heading"
			:body="locale.mixedOwnership.utilityOnly.body"
		/>

		<ErrorOverlay
			v-if="popup === PopupStates.AUTH_SIGN_OUT"
			heading="Signed out"
			message="You are now signed out of the experience."
			cta-label="Refresh"
			:close-to-refresh="true"
			@cta-click="refresh"
		/>
		<ErrorOverlay
			v-if="popup === PopupStates.ERROR"
			:heading="error.heading"
			:message="error.message"
			cta-label="Refresh"
			@cta-click="refresh"
		/>
		<ErrorOverlay
			v-if="popup === PopupStates.NO_METAMASK"
			heading="Error"
			message="Whoops, looks like you don’t have metamask installed on your browser. Please install to try again."
			cta-label="Install now"
			:close-to-refresh="true"
			@cta-click="open('https://metamask.io/')"
		/>
		<ErrorOverlay
			v-if="popup === PopupStates.AUTH_NO_NFTS"
			heading="Error"
			message="No Mars Jackets have been found in your wallet. Head over to our Decentraland store to discover and shop our range of Mars ready wearables for both you and your avatar."
			cta-label="Install now"
			@cta-click="open('https://market.decentraland.org/collections/0x078e97db984e5ddda4cd5753bdf9cf2fc87b1422')"
		/>
	</div>
</template>

<script>
import ErrorOverlay from '@/screens/ErrorOverlay.vue';
import LoadingScreen from '@/screens/LoadingScreen.vue';
import SignInScreen from '@/screens/SignInScreen.vue';
import EditScreen from '@/screens/EditScreen.vue';
import SuccessScreen from '@/screens/SuccessScreen.vue';
import NoticeScreen from '@/screens/NoticeScreen.vue';
import StaffScreen from './screens/StaffScreen.vue';
import locale from '@/data/Locale';
import ScreenStates from '@/data/ScreenStates';
import PopupStates from '@/data/PopupStates';

export default {
	name: 'App',
	components: {
		SignInScreen,
		LoadingScreen,
		ErrorOverlay,
		EditScreen,
		SuccessScreen,
		NoticeScreen,
		StaffScreen,
	},
	methods: {
		refresh() {
			window.location = '/';
		},
		open(url) {
			window.location = url;
		},
	},
	computed: {
		classes() {
			return {
				'has-popup': this.popup !== null,
			};
		},
		ScreenStates() {
			return ScreenStates;
		},
		PopupStates() {
			return PopupStates;
		},
		locale() {
			return locale;
		},
		error() {
			return this.$store.state.error || {};
		},
		screen() {
			return this.state.screen;
		},
		popup() {
			return this.state.popup;
		},
		state() {
			if (new URLSearchParams(window.location.search).has('signout')) {
				this.$store.dispatch('signOut');

				return {
					popup: PopupStates.AUTH_SIGN_OUT,
					screen: ScreenStates.SIGN_IN,
				};
			} else if (new URLSearchParams(window.location.search).has('admin') && this.$store.getters.isStaffClaim) {
				return {
					popup: null,
					screen: ScreenStates.AUTH_STAFF,
				};
			} else if (this.$store.getters.hasEthereumProvider === false) {
				return {
					popup: PopupStates.NO_METAMASK,
					screen: ScreenStates.SIGN_IN,
				};
			} else if (this.$store.getters.isAuthenticated === false || this.$store.getters.hasExperienceData === false) {
				return {
					popup: this.$store.getters.hasError ? PopupStates.ERROR : null,
					screen: ScreenStates.SIGN_IN,
				};
			} else if (this.$store.getters.isAuthenticated && this.$store.getters.hasExperienceData) {
				const ownership = this.$store.getters.ownership;

				if (ownership.anyOwned === false) {
					return {
						popup: PopupStates.AUTH_NO_NFTS,
						screen: ScreenStates.SIGN_IN,
					};
				} else if (ownership.mixedOwnership && this.$store.state.dismissMixedOwnership === false) {
					if (ownership.anyPhysical) {
						return {
							popup: this.$store.getters.hasError ? PopupStates.ERROR : null,
							screen: ScreenStates.AUTH_MIXED_OWNERSHIP_PHYSICAL,
						};
					} else {
						return {
							popup: this.$store.getters.hasError ? PopupStates.ERROR : null,
							screen: ScreenStates.AUTH_MIXED_OWNERSHIP,
						};
					}
				} else if (this.$store.getters.isShowEditSuccess) {
					return {
						popup: this.$store.getters.hasError ? PopupStates.ERROR : null,
						screen: ScreenStates.AUTH_EDIT_SUCCESS,
					};
				} else {
					return {
						popup: this.$store.getters.hasError ? PopupStates.ERROR : null,
						screen: ScreenStates.AUTH_EDIT,
					};
				}
			} else {
				return {
					popup: this.$store.getters.hasError ? PopupStates.ERROR : null,
					screen: ScreenStates.INITIAL_LOAD,
				};
			}
		},
	},
};
</script>

<style lang="scss">
@font-face {
	font-family: "Suisse Intl";
	src: url("../public/assets/fonts/SuisseIntl-Regular.woff2") format("woff2"),
		url("../public/assets/fonts/SuisseIntl-Regular.woff") format("woff");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Suisse Intl";
	src: url("../public/assets/fonts/SuisseIntl-SemiBold.woff2") format("woff2"),
		url("../public/assets/fonts/SuisseIntl-SemiBold.woff") format("woff");
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: "Suisse Intl Condensed";
	src: url("../public/assets/fonts/SuisseIntlCond-Semibold.woff2") format("woff2"),
		url("../public/assets/fonts/SuisseIntlCond-Semibold.woff") format("woff");
	font-style: normal;
	font-weight: 500;
}

// body {
// 	&::after {
// 		content: "?";
// 		background: black;
// 		color: white;
// 		font-family: monospace;
// 		font-size: 8pt;
// 		padding: 4px;
// 		line-height: 1;
// 		left: 0;
// 		top: 0;
// 		position: fixed;
// 		z-index: 1000;
// 		@each $keyword in $breakpoint-keys {
// 			@include bp($keyword) {
// 				content: "#{$keyword}";
// 			}
// 		}
// 	}
// }

html,
body {
	margin: 0;
	padding: 0;
	line-height: 1;
	box-sizing: border-box;
	font-family: "Suisse Intl", sans-serif;
	font-weight: 400;
}

*,
*::after,
*::before {
	box-sizing: inherit;
}

*:first-child { margin-top: 0; }
*:last-child { margin-bottom: 0; }

#app {
	background: #FE5921;
	display: flex;
	flex: 1 1 100%;
	flex-flow: column nowrap;
	min-height: 100vh;
	min-width: 320px;

	&.has-popup {
		> .screen {
			user-select: none;
			pointer-events: none;
		}
	}
}

.heading {
	margin: 0;
	color: black;
	font-style: normal;
	@include font-size(80px);
	line-height: 0.93;
	font-weight: 600;
	letter-spacing: 0.005em;
	text-transform: uppercase;
}

.leader {
	color: black;
	font-style: normal;
	font-weight: normal;
	font-weight: 400;
	@include font-size(72px);
	line-height: 110%;

	* + & {
		margin: 22px 0 0;
	}
}

button, .button {
	font-family: "Suisse Intl", sans-serif;
	font-weight: 600;
	appearance: none;
	margin: 0;
	padding: 0;
	display: inline-block;
	border: 0;
	cursor: pointer;
}

.vh {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.sub-heading {
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.s-wide {
	display: none;

	@include bp(ml) {
		display: block;
	}
}

.s-narrow {
	display: block;

	@include bp(ml) {
		display: none;
	}
}
</style>
