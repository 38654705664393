<template>
	<div class="control">
		<label class="control__label" :for="id">{{ label }}</label>
		<div class="control__field">
			<select :disabled="disabled" v-bind:value="intermediateValue" v-on:input="$emit('input', $event.target.value)" :id="id">
				<option v-for="option in options" :key="option.key" :value="option.key">{{ option.label }}</option>
			</select>
		</div>
	</div>
</template>

<script>
import { generatePushID } from '../Utils';

export default {
	name: 'SelectField',
	props: {
		label: {
			type: String,
			default: 'Label',
		},
		value: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		options: Array,
	},
	data() {
		return {
			id: `field-${generatePushID()}`,
			intermediateValue: this.value || this.options[0].key,
		};
	},
};
</script>

<style lang="scss" scoped>
.control {
	background: white;
	position: relative;
}

.control__label {
	display: block;
	font-weight: 500;
	font-size: 10px;
	line-height: 1;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	padding: 10px 0 0 16px;
}

.control__field {
	select {
		width: 100%;
		border: 0;
		appearance: none;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 1;
		background: 0;
		margin: 0;
		padding: 0 10px 10px;
		height: 36px;
	}

	&:after {
		background: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMjQgMzYiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xMS44OTk2IDIzLjMxMzctLjAwMDEuMDAwMi05Ljg5OTUtOS44OTk1IDEuNDE0MjEtMS40MTQyIDguNDg1MjkgOC40ODUyIDguNDg1NC04LjQ4NTQgMS40MTQyIDEuNDE0Mi04LjQ4NTQgOC40ODU0eiIgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
		position: absolute;
		right: 12px;
		top: calc(50% - 15px);
		width: 34px;
		height: 34px;
		content: "";
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
}
</style>
