<template>
	<PrimaryScreen class="screen--sign-in">
		<template v-slot>
			<h1 class="heading">{{ heading }} </h1>
			<p class="leader">{{ body }} </p>
		</template>

		<template v-slot:bottom>
			<div class="button-container">
				<PrimaryButton v-on:click="dismissMixedOwnership" label="Register" />
			</div>
		</template>
	</PrimaryScreen>
</template>

<script>
import PrimaryScreen from '../components/PrimaryScreen.vue';
import PrimaryButton from '../components/PrimaryButton.vue';

export default {
	name: 'NoticeScreen',
	props: {
		heading: String,
		body: String,
	},
	components: {
		PrimaryScreen,
		PrimaryButton,
	},
	methods: {
		dismissMixedOwnership() {
			this.$store.dispatch('dismissMixedOwnership');
		},
	},
};
</script>
