export default () => {
	return {
		size: '',
		firstName: '',
		lastName: '',
		address1: '',
		address2: '',
		city: '',
		country: '',
		postcode: '',
		phone: '',
		email: '',
	};
};
