<template>
  <Screen class="screen--loading">
  </Screen>
</template>

<script>
import Screen from '@/components/Screen.vue';

export default {
	name: 'LoadingScreen',
	components: {
		Screen,
	},
};
</script>

<style lang="scss">
.screen--loading {
	background: url("@../../../public/assets/logo.png"); // This is dumb
	background-repeat: no-repeat;
	background-position: center;
	background-size: 60px auto;
}
</style>
