import { dirtyParseJwt } from '@/Utils';
import Claims from '@/data/Claims';

export default (state, {signedInState, walletAddress, accessToken }) => {
	console.info('🦑 SET_SIGNED_IN_STATE', {signedInState, walletAddress});

	if (typeof accessToken === 'string') {
		state.auth.claims = Object.entries(dirtyParseJwt(accessToken)).reduce((temp, [k, v]) => {
			if (Claims.includes(k)) {
				temp[k] = v;
			}
			return temp;
		}, {});
	} else {
		state.auth.claims = [];
	}

	state.auth.isAuthenticated = signedInState;
	state.user.walletAddress = signedInState ? walletAddress : null;
};
