import { getFirestore, getDocs, collection } from '@firebase/firestore/lite';

export default async ({ commit }) => {
	const firestore = getFirestore();
	const colRef = collection(firestore, 'vbk_dcl_nfts');
	const snapshot = await getDocs(colRef);

	snapshot.forEach(doc => {
		if (doc.exists()) {
			commit('SET_NFT_DATA', doc.data());
		}
	});
};
