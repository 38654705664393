<template>
	<Screen class="screen--staff">
		<template v-slot>
			<h1>Test Claims</h1>

			<h2>Add test products</h2>

			<table class="table--add">
				<tr v-for="row in fakeProducts" :key="[row.contractAddress, row.itemID].join(':')">
					<th>{{ row.name }}</th>
					<td>{{ row.sub }}</td>
					<td><button class="button--little" @click.prevent="add(row)">Add</button></td>
				</tr>
			</table>

			<h2>Manage test products</h2>

			<p>Adjust claims to fake products for testing purposes:</p>

			<table class="table--test">
				<thead>
					<tr>
						<th>Issue ID</th>
						<th>Name</th>
						<th>Status</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="row in claims" :key="row.issueID">
						<td><code>{{ row.issueID }}</code></td>
						<th><strong>{{ row.name }}</strong> {{ row.sub }}</th>
						<td>
							<ul>
								<li v-for="(value, name) in statuses" :key="value">
									<input :value="value" v-model="row.status" :name="`status-${row.issueID}`" type="radio" :id="`status-${row.issueID}-${value}`" >
									<label :for="`status-${row.issueID}-${value}`">{{ name }}</label>
								</li>
							</ul>
						</td>
						<td>
							<button class="button--little" @click.prevent="remove(row)">Remove</button>
						</td>
					</tr>
				</tbody>
			</table>

			<ArrowButton :label="label" @click="update" />
		</template>
	</Screen>
</template>

<script>
import Screen from '../components/Screen.vue';
import ArrowButton from '../components/ArrowButton.vue';
import FakeProducts from '@/data/FakeProducts';
import FakeProductStatus from '@/data/FakeProductStatus';

export default {
	name: 'NoticeScreen',
	props: {
		heading: String,
		body: String,
	},
	data() {
		return {
			i: Math.floor(Math.random() * 100),
			claims: this.getFromLocalStorage(),
		};
	},
	components: {
		Screen,
		ArrowButton,
	},
	computed: {
		label() {
			const d = [
				'BAM',
				'UPDATE',
				'DO IT',
				'IS THIS BILLABLE?',
				'PRESS ME',
				'CTA LABEL TODO',
				'SPRAY AND PRAY',
				'l33t H4xx0r',
				'PUSH IT',
				'🙏',
				'FAKE THE THINGS',
				'HACK THE PLANET',
				'FAKE IT',
			];

			return d[(this.i + this.claims.length) % d.length];
		},
		fakeProducts() {
			return FakeProducts;
		},
		statuses() {
			return FakeProductStatus;
		},
	},
	watch: {
		claims: {
			deep: true,
			handler(newValue) {
				try {
					const d = JSON.stringify(newValue);

					window.localStorage.setItem('test_products', d);
				} catch (e) {
					console.error(e);
				}
			},
		},
	},
	methods: {
		update() {
			this.$store.dispatch('updateTestClaims', {
				claims: this.claims,
			});
		},
		add(product) {
			this.claims.push({
				issueID: Math.floor(Math.random() * 1000000) + 1000000,
				name: product.name,
				sub: product.sub,
				contractAddress: product.contractAddress,
				status: FakeProductStatus.UNCLAIMED,
				itemID: product.itemID,
			});
		},
		remove(product) {
			const index = this.claims.find(x => x.issueID === product.issueID);

			if (index) {
				this.claims.splice(index, 1);
			}
		},
		getFromLocalStorage() {
			try {
				const d = window.localStorage.getItem('test_products');

				return d ? JSON.parse(d) : [];
			} catch (e) {
				return [];
			}
		},
	},
};
</script>

<style lang="scss">
.screen--staff {
	padding: 40px;

	h1, h2, p, table {
		margin: 10px 0;
	}

	ul, li {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	table {
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;

		th, td {
			vertical-align: top;
			padding: 8px;
			line-height: 1;
			border: solid 1px black;
			text-align: left;
		}
	}

	.table--add {
		th, td {
			vertical-align: middle;
		}
	}

	.button--little {
		background: black;
		color: white;
		padding: 8px;
		text-transform: uppercase;

		&:not(:disabled) {
			&:focus,
			&:hover {
				background: white;
				color: black;
			}
		}
	}
}
</style>
