export default [
	{ key: 'GB', label: 'United Kingdom' },
	{ key: 'US', label: 'United States' },
	{ key: 'AU', label: 'Australia' },
	{ key: 'AF', label: 'Afghanistan' },
	{ key: 'AL', label: 'Albania' },
	{ key: 'DZ', label: 'Algeria' },
	{ key: 'AD', label: 'Andorra' },
	{ key: 'AO', label: 'Angola' },
	{ key: 'AG', label: 'Antigua and Barbuda' },
	{ key: 'AR', label: 'Argentina' },
	{ key: 'AM', label: 'Armenia' },
	{ key: 'AT', label: 'Austria' },
	{ key: 'AZ', label: 'Azerbaijan' },
	{ key: 'BH', label: 'Bahrain' },
	{ key: 'BD', label: 'Bangladesh' },
	{ key: 'BB', label: 'Barbados' },
	{ key: 'BY', label: 'Belarus' },
	{ key: 'BE', label: 'Belgium' },
	{ key: 'BZ', label: 'Belize' },
	{ key: 'BJ', label: 'Benin' },
	{ key: 'BT', label: 'Bhutan' },
	{ key: 'BO', label: 'Bolivia' },
	{ key: 'BA', label: 'Bosnia and Herzegovina' },
	{ key: 'BW', label: 'Botswana' },
	{ key: 'BR', label: 'Brazil' },
	{ key: 'BN', label: 'Brunei' },
	{ key: 'BG', label: 'Bulgaria' },
	{ key: 'BF', label: 'Burkina Faso' },
	{ key: 'BI', label: 'Burundi' },
	{ key: 'KH', label: 'Cambodia' },
	{ key: 'CM', label: 'Cameroon' },
	{ key: 'CA', label: 'Canada' },
	{ key: 'CV', label: 'Cape Verde' },
	{ key: 'CF', label: 'Central African Republic' },
	{ key: 'TD', label: 'Chad' },
	{ key: 'CL', label: 'Chile' },
	{ key: 'CN', label: 'China' },
	{ key: 'CO', label: 'Colombia' },
	{ key: 'KM', label: 'Comoros' },
	{ key: 'CG', label: 'Congo' },
	{ key: 'CD', label: 'Congo (Democratic Republic)' },
	{ key: 'CR', label: 'Costa Rica' },
	{ key: 'HR', label: 'Croatia' },
	{ key: 'CU', label: 'Cuba' },
	{ key: 'CY', label: 'Cyprus' },
	{ key: 'CZ', label: 'Czechia' },
	{ key: 'DK', label: 'Denmark' },
	{ key: 'DJ', label: 'Djibouti' },
	{ key: 'DM', label: 'Dominica' },
	{ key: 'DO', label: 'Dominican Republic' },
	{ key: 'TL', label: 'East Timor' },
	{ key: 'EC', label: 'Ecuador' },
	{ key: 'EG', label: 'Egypt' },
	{ key: 'SV', label: 'El Salvador' },
	{ key: 'GQ', label: 'Equatorial Guinea' },
	{ key: 'ER', label: 'Eritrea' },
	{ key: 'EE', label: 'Estonia' },
	{ key: 'SZ', label: 'Eswatini' },
	{ key: 'ET', label: 'Ethiopia' },
	{ key: 'FJ', label: 'Fiji' },
	{ key: 'FI', label: 'Finland' },
	{ key: 'FR', label: 'France' },
	{ key: 'GA', label: 'Gabon' },
	{ key: 'GE', label: 'Georgia' },
	{ key: 'DE', label: 'Germany' },
	{ key: 'GH', label: 'Ghana' },
	{ key: 'GR', label: 'Greece' },
	{ key: 'GD', label: 'Grenada' },
	{ key: 'GT', label: 'Guatemala' },
	{ key: 'GN', label: 'Guinea' },
	{ key: 'GW', label: 'Guinea-Bissau' },
	{ key: 'GY', label: 'Guyana' },
	{ key: 'HT', label: 'Haiti' },
	{ key: 'HN', label: 'Honduras' },
	{ key: 'HU', label: 'Hungary' },
	{ key: 'IS', label: 'Iceland' },
	{ key: 'IN', label: 'India' },
	{ key: 'ID', label: 'Indonesia' },
	{ key: 'IR', label: 'Iran' },
	{ key: 'IQ', label: 'Iraq' },
	{ key: 'IE', label: 'Ireland' },
	{ key: 'IL', label: 'Israel' },
	{ key: 'IT', label: 'Italy' },
	{ key: 'CI', label: 'Ivory Coast' },
	{ key: 'JM', label: 'Jamaica' },
	{ key: 'JP', label: 'Japan' },
	{ key: 'JO', label: 'Jordan' },
	{ key: 'KZ', label: 'Kazakhstan' },
	{ key: 'KE', label: 'Kenya' },
	{ key: 'KI', label: 'Kiribati' },
	{ key: 'XK', label: 'Kosovo' },
	{ key: 'KW', label: 'Kuwait' },
	{ key: 'KG', label: 'Kyrgyzstan' },
	{ key: 'LA', label: 'Laos' },
	{ key: 'LV', label: 'Latvia' },
	{ key: 'LB', label: 'Lebanon' },
	{ key: 'LS', label: 'Lesotho' },
	{ key: 'LR', label: 'Liberia' },
	{ key: 'LY', label: 'Libya' },
	{ key: 'LI', label: 'Liechtenstein' },
	{ key: 'LT', label: 'Lithuania' },
	{ key: 'LU', label: 'Luxembourg' },
	{ key: 'MG', label: 'Madagascar' },
	{ key: 'MW', label: 'Malawi' },
	{ key: 'MY', label: 'Malaysia' },
	{ key: 'MV', label: 'Maldives' },
	{ key: 'ML', label: 'Mali' },
	{ key: 'MT', label: 'Malta' },
	{ key: 'MH', label: 'Marshall Islands' },
	{ key: 'MR', label: 'Mauritania' },
	{ key: 'MU', label: 'Mauritius' },
	{ key: 'MX', label: 'Mexico' },
	{ key: 'FM', label: 'Micronesia' },
	{ key: 'MD', label: 'Moldova' },
	{ key: 'MC', label: 'Monaco' },
	{ key: 'MN', label: 'Mongolia' },
	{ key: 'ME', label: 'Montenegro' },
	{ key: 'MA', label: 'Morocco' },
	{ key: 'MZ', label: 'Mozambique' },
	{ key: 'MM', label: 'Myanmar (Burma)' },
	{ key: 'NA', label: 'Namibia' },
	{ key: 'NR', label: 'Nauru' },
	{ key: 'NP', label: 'Nepal' },
	{ key: 'NL', label: 'Netherlands' },
	{ key: 'NZ', label: 'New Zealand' },
	{ key: 'NI', label: 'Nicaragua' },
	{ key: 'NE', label: 'Niger' },
	{ key: 'NG', label: 'Nigeria' },
	{ key: 'KP', label: 'North Korea' },
	{ key: 'MK', label: 'North Macedonia' },
	{ key: 'NO', label: 'Norway' },
	{ key: 'OM', label: 'Oman' },
	{ key: 'PK', label: 'Pakistan' },
	{ key: 'PW', label: 'Palau' },
	{ key: 'PA', label: 'Panama' },
	{ key: 'PG', label: 'Papua New Guinea' },
	{ key: 'PY', label: 'Paraguay' },
	{ key: 'PE', label: 'Peru' },
	{ key: 'PH', label: 'Philippines' },
	{ key: 'PL', label: 'Poland' },
	{ key: 'PT', label: 'Portugal' },
	{ key: 'QA', label: 'Qatar' },
	{ key: 'RO', label: 'Romania' },
	{ key: 'RU', label: 'Russia' },
	{ key: 'RW', label: 'Rwanda' },
	{ key: 'WS', label: 'Samoa' },
	{ key: 'SM', label: 'San Marino' },
	{ key: 'ST', label: 'Sao Tome and Principe' },
	{ key: 'SA', label: 'Saudi Arabia' },
	{ key: 'SN', label: 'Senegal' },
	{ key: 'RS', label: 'Serbia' },
	{ key: 'SC', label: 'Seychelles' },
	{ key: 'SL', label: 'Sierra Leone' },
	{ key: 'SG', label: 'Singapore' },
	{ key: 'SK', label: 'Slovakia' },
	{ key: 'SI', label: 'Slovenia' },
	{ key: 'SB', label: 'Solomon Islands' },
	{ key: 'SO', label: 'Somalia' },
	{ key: 'ZA', label: 'South Africa' },
	{ key: 'KR', label: 'South Korea' },
	{ key: 'SS', label: 'South Sudan' },
	{ key: 'ES', label: 'Spain' },
	{ key: 'LK', label: 'Sri Lanka' },
	{ key: 'KN', label: 'St Kitts and Nevis' },
	{ key: 'LC', label: 'St Lucia' },
	{ key: 'VC', label: 'St Vincent' },
	{ key: 'SD', label: 'Sudan' },
	{ key: 'SR', label: 'Suriname' },
	{ key: 'SE', label: 'Sweden' },
	{ key: 'CH', label: 'Switzerland' },
	{ key: 'SY', label: 'Syria' },
	{ key: 'TJ', label: 'Tajikistan' },
	{ key: 'TZ', label: 'Tanzania' },
	{ key: 'TH', label: 'Thailand' },
	{ key: 'BS', label: 'The Bahamas' },
	{ key: 'GM', label: 'The Gambia' },
	{ key: 'TG', label: 'Togo' },
	{ key: 'TO', label: 'Tonga' },
	{ key: 'TT', label: 'Trinidad and Tobago' },
	{ key: 'TN', label: 'Tunisia' },
	{ key: 'TR', label: 'Turkey' },
	{ key: 'TM', label: 'Turkmenistan' },
	{ key: 'TV', label: 'Tuvalu' },
	{ key: 'UG', label: 'Uganda' },
	{ key: 'UA', label: 'Ukraine' },
	{ key: 'AE', label: 'United Arab Emirates' },
	{ key: 'UY', label: 'Uruguay' },
	{ key: 'UZ', label: 'Uzbekistan' },
	{ key: 'VU', label: 'Vanuatu' },
	{ key: 'VA', label: 'Vatican City' },
	{ key: 'VE', label: 'Venezuela' },
	{ key: 'VN', label: 'Vietnam' },
	{ key: 'YE', label: 'Yemen' },
	{ key: 'ZM', label: 'Zambia' },
	{ key: 'ZW', label: 'Zimbabwe' },
];
