import Promise from 'bluebird';
import { getFirestore, getDocs, query, where, collection } from '@firebase/firestore/lite';

export default async ({ state, commit }) => {
	const firestore = getFirestore();
	const colRef = collection(firestore, 'vbk_dcl_nft_claims');

	const snapshots = await Promise.all([
		getDocs(query(colRef, where('claimed_by', '==', state.user.walletAddress))),
		getDocs(query(colRef, where('last_seen_owner', '==', state.user.walletAddress))),
	]);

	commit('CLEAR_CLAIM_DATA');

	if (state.nfts === null) {
		throw new Error(`state.nfts was accessed before it was retrieved: ${state.nfts}`);
	}
	const validContractAddresses = Object.values(state.nfts).map(x => x.contract_address);

	snapshots.forEach(snapshot => {
		snapshot.forEach(doc => {
			if (doc.exists()) {
				const claim = doc.data();

				if (validContractAddresses.includes(claim.contract_address)) {
					commit('SET_CLAIM_DATA', claim);
				}
			}
		});
	});
};
