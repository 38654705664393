<template>
	<div class="control">
		<div class="control__label sub-heading">{{ label }}</div>
		<div class="control__field">
			<ul>
				<li v-for="(option, index) in options" :key="option">
					<input
						:id="`${id}-${index}`"
						type="radio"
						:name="`sizing-${id}`"
						class="vh"
						:disabled="disabled"
						v-bind:value="option"
						v-model="selected"
						v-on:input="$emit('input', $event.target.value)">
					<label :for="`${id}-${index}`">
						<span>{{ option }}</span>
					</label>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { generatePushID } from '../Utils';

export default {
	name: 'SizeSelect',
	props: {
		value: String,
		label: String,
		options: Array,
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			id: `field-${generatePushID()}`,
			selected: this.value,
		};
	},
};
</script>

<style lang="scss" scoped>
.control__field {
	ul {
		margin: 16px 0 0;
		padding: 0;
		list-style: none;
		display: flex;
		gap: 16px;
	}

	label {
		display: block;
		color: black;
		line-height: 1;
		border: solid 1px black;
		color: black;

		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		span {
			user-select: none;
		}
	}

	input:checked + label {
		background: black;
		color: white;
	}
}

@include bp-max(l) {
	.control__field {
		ul {
			flex-flow: row wrap;
		}

		li {
			flex: 1 1 45%;
			max-width: 50%;
		}

		label {
			font-weight: 500;
			font-size: 36px;
			line-height: 36px;
			height: 80px;
		}
	}
}

@include bp(l) {
	.control__field {
		ul {
			flex-flow: row nowrap;
			gap: 16px;
		}
		li {
			flex: 1 1 auto;
		}
		label {
			font-style: normal;
			font-weight: 500;
			font-size: 36px;
			height: 80px;
		}
	}
}
</style>
