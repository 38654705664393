import {getFirestore, getDocs, query, orderBy, limit, collection } from '@firebase/firestore/lite';

export default async ({ state, commit }) => {
	console.info('🎬 fetchFulfilment');
	const firestore = getFirestore();
	const colRef = collection(firestore, 'vbk_wallet_user_data', state.user.walletAddress, 'shipping');
	const snapshot = await getDocs(query(colRef, orderBy('created_at', 'desc'), limit(1)));

	snapshot.forEach(doc => {
		if (doc.exists()) {
			try {
				const data = JSON.parse(doc.data().shipping);

				commit('SET_FULFILMENT_DATA', data);
			} catch (e) {
				console.error('⛔️ Unable to parse pre-existing shipping data.', e);
			}
		}
	});
};
