export const dirtyParseJwt = token => {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/gu, '+').replace(/_/gu, '/');

	const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
};

/**
 * Generate a push ID (similar to how Firebase does it) that is also sortable based on time
 * Copied from Gist: https://gist.github.com/mikelehen/3596a30bd69384624c11
 */
export const generatePushID = (function () {
	const PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';
	let lastPushTime = 0;
	const lastRandChars = [];

	return () => {
		let now = new Date().getTime();
		let i;
		const duplicateTime = now === lastPushTime;

		lastPushTime = now;

		const timeStampChars = new Array(8);

		for (i = 7; i >= 0; i -= 1) {
			timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
			now = Math.floor(now / 64);
		}
		if (now !== 0) {
			throw new Error('We should have converted the entire timestamp.');
		}

		let id = timeStampChars.join('');

		if (duplicateTime) {
			for (i = 0; i < 12; i += 1) {
				lastRandChars[i] = Math.floor(Math.random() * 64);
			}
		} else {
			for (i = 11; i >= 0 && lastRandChars[i] === 63; i -= 1) {
				lastRandChars[i] = 0;
			}
			lastRandChars[i] += 1;
		}
		for (i = 0; i < 12; i += 1) {
			id += PUSH_CHARS.charAt(lastRandChars[i]);
		}
		if (id.length !== 20) {
			throw new Error('Length should be 20.');
		}

		return id;
	};
}());

