<template>
	<button class="button--arrow" :disabled="disabled" @click.prevent="$emit('click')">
		<span class="label">{{ label }}</span>
		<div class="arrow"></div>
	</button>
</template>

<script>
export default {
	name: 'ArrowButton',
	props: {
		label: String,
		disabled: Boolean,
	},
};
</script>

<style lang="scss">
@use "sass:math";

.button--arrow {
	width: 100%;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 30px;
	height: 50px;
	position: relative;
	background: black;
	padding: 0 16px;
	display: flex;
	flex-flow: row nowrap;
    align-items: stretch;

	.label {
		overflow: hidden;
		white-space: nowrap;
		color: white;
		text-align: left;
		display: block;
		line-height: 0.875;
		flex-flow: row;
		display: flex;
		align-items: center;
		z-index: 100;
		flex: 0 0 auto;
	}

	.arrow {
		flex: 1 1 auto;
		overflow: hidden;
		position: relative;

		&:after,
		&:before {
			content: "";
			position: absolute;
			left: 0;
			width: 100%;
			background: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWF4WU1pZCBzbGljZSIgaGVpZ2h0PSI0Ny41NjRtbSIgdmlld0JveD0iMCAwIDE0OTQuNTQwMyAxMzQuODI3MSIgd2lkdGg9IjUyNy4yNDA2bW0iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0id2hpdGUiIGQ9Im0xNDkyLjM3IDY1LjI0Ny0uODktLjg5OC02NC4zNS02NC4zNDktMy4wNyAzLjA2NCA2Mi4xOCA2Mi4xODNoLTE0ODQuMDczbC0yLjE2NyAyLjE2NyAyLjE2NyAyLjE2NmgxNDg0LjA3M2wtNjIuMTggNjIuMTgyIDMuMDcgMy4wNjUgNjQuMzUtNjQuMzQ5Ljg5LS44OTggMi4xNy0yLjE2N3oiLz48L3N2Zz4=');
			background-position: center right;
			background-size: auto 100%;
			background-repeat: no-repeat;
		}
		&:after {
			transform: translate(-100%, 0);
		}
	}

	&:disabled {
		&, .label {
			color: grey;
			background: rgb(37, 37, 37);
		}

		.arrow {
			opacity: 0.5;
		}
	}

	&:not(:disabled) {
		&:hover,
		&:active {
			.arrow {
				&:after,
				&:before {
					transition-property: transform;
					transition-duration: 500ms;
				}

				&:after {
					transition-delay: 200ms;
					transform: translate(0%, 0);
				}

				&:before {
					transform: translate(100%, 0);
				}
			}
		}
	}
}

@mixin button-arrow-padding($h, $v) {
	padding: 0 $h;
	gap: $h;

	.arrow {
		&:after,
		&:before {
			top: $v;
			bottom: $v;
		}
	}
}

@include bp-max(s) {
	.button--arrow {
		@include button-arrow-padding(16px, 11px);
	}
}

@include bp(s) {
	.button--arrow {
		@include button-arrow-padding(16px, 14px);
		font-size: 40px;
		height: 60px;
	}
}

@include bp(m) {
	.button--arrow {
		@include button-arrow-padding(16px, 16px);
		font-size: 60px;
		height: 72px;
	}
}
</style>
