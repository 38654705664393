import Vue from 'vue';
import App from './App.vue';
import store from '@/store';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Initialise Firebase
const firebaseApp = initializeApp({
	apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
	authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_FIREBASE_APP_ID,
});

// Listen for changes in auth state
const auth = getAuth(firebaseApp);

onAuthStateChanged(auth, async user => {
	if (user && user.accessToken) {
		console.info('🔑 User has authenticated');
		await store.dispatch('setSignInState', {
			signedInState: true,
			walletAddress: user.uid,
			accessToken: user.accessToken,
		});
	} else if (user && user.isAnonymous) {
		console.info('🕵️‍♀️ User is unexpectedly anonymous');
		await store.dispatch('setSignInState', { signedInState: false });
	} else {
		console.info('🔑 User is not authenticated');
		await store.dispatch('setSignInState', { signedInState: false });
	}
}, e => {
	console.error('⛔️ An error occured during onAuthStateChanged()');
	console.error(e);
});

Vue.config.productionTip = false;

new Vue({
	store,
	render: h => h(App),
}).$mount('#app');
