export default async ({ dispatch }) => {
	console.info('🎬 fetchAll');

	await Promise.all([
		dispatch('fetchUserData'),
		dispatch('fetchNFTs'),
		dispatch('fetchFulfilment'),
	]);

	// Call fetchClaims after fetchNFTs as we want to filter claims that
	// are not connected with one of the specified NFTs.
	await dispatch('fetchClaims');
};
