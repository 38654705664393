<template>
	<div class="screen">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'Screen',
};
</script>

<style lang="scss">
.screen {
	display: flex;
	flex-flow: column nowrap;
	flex: 1 1 100%;
}
</style>
