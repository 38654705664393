import { ServerError } from '@/Exceptions';

export default ({ state }) => {
	console.info('🎬 refreshInventory');
	const u = new URL(process.env.VUE_APP_REFRESH_INVENTORY_API, process.env.VUE_APP_API_BASE);

	return fetch(u, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			walletAddress: state.user.walletAddress,
		}),
	}).then(response => {
		return response.json();
	}).then(data => {
		if (data.error) {
			throw new ServerError(data);
		}

		return true;
	}).catch(e => {
		console.error('⛔️ Error refreshing inventory');
		throw e;
	});
};
