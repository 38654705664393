<template>
	<Screen class="screen--primary">
		<div class="wrap">
			<div class="content content--left">
				<div v-if="logo" class="logo">
					<Logo />
				</div>
				<slot name="secondary"></slot>
			</div>

			<div class="content content--right">
				<slot></slot>
			</div>
		</div>

		<div v-if="$slots.bottom">
			<slot name="bottom"></slot>
		</div>
	</Screen>
</template>

<script>
import Screen from './Screen.vue';
import Logo from './Logo.vue';

export default {
	name: 'PrimaryScreen',
	components: {
		Logo,
		Screen,
	},
	props: {
		logo: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss">

.screen--primary {
	.logo {
		video, img {
			user-select: none;
			pointer-events: none;
			display: block;
			width: 100%;
			height: auto;
			object-fit: cover;
			object-position: center;
		}
	}

	> .wrap {
		display: flex;
		flex-flow: column nowrap;
		gap: 20px;
		padding: 20px;
		flex: 1 1 auto;
	}

	> .button-container {
		overflow: hidden;
		flex: 0 0 auto;
	}
}

@include bp-max(ml) {
	.screen--primary {
		> .wrap {
			.logo {
				max-width: 200px;
			}
		}
	}
}

@include bp(m) {
	.screen--primary {
		> .wrap {
			.logo {
				max-width: 300px;
			}
		}
	}
}

@include bp(ml) {
	.screen--primary {
		> .wrap {
			.logo {
				max-width: none;
			}
		}
	}
}

@include bp(ml) {
	.screen--primary {
		> .wrap {
			padding: 25px;
			flex-flow: row nowrap;
			justify-content: space-between;
			gap: 100px;

			.logo {
				flex: 1 1 auto;
			}

			.content--left {
				display: flex;
				flex: 1 1 auto;
				flex-flow: column nowrap;
				justify-content: flex-end;
				width: 100%;
				min-width: 200px;
				max-width: 400px;
			}

			.content--right {
				flex: 1 1 auto;
			}
		}
	}
}
</style>
