<template>
	<PrimaryScreen class="screen--sign-in">
		<template v-slot>
			<p class="leader">{{ locale.body }}</p>

			<div class="s-narrow">
				<p class="contact">{{ locale.contact }}</p>
				<p class="email"><a :href="emailLink">{{ locale.email }}</a></p>
			</div>
		</template>

		<template v-slot:secondary>
			<div class="s-wide">
				<p class="contact">{{ locale.contact }}</p>
				<p class="email"><a :href="emailLink">{{ locale.email }}</a></p>
			</div>
		</template>

		<template v-slot:bottom>
			<div class="button-container">
				<PrimaryButton :disabled="isSigningIn" @click="connectMetamask" :label="locale.cta" />
			</div>
		</template>
	</PrimaryScreen>
</template>

<script>
import locale from '@/data/Locale';
import PrimaryScreen from '../components/PrimaryScreen.vue';
import PrimaryButton from '../components/PrimaryButton.vue';

export default {
	name: 'SignInScreen',
	components: {
		PrimaryScreen,
		PrimaryButton,
	},
	computed: {
		locale() {
			return locale.signIn;
		},
		emailLink() {
			return `mailto:${this.locale.email}?subject=` + encodeURIComponent(this.locale.emailSubject);
		},
		isSigningIn() {
			return this.$store.getters.isSigningIn;
		},
	},
	methods: {
		connectMetamask() {
			this.$store.dispatch('signIn');
		},
	},
};
</script>

<style lang="scss">
.screen--sign-in {
	.contact,
	.email {
		max-width: 400px;
		font-size: 16px;
		line-height: 24px;
		margin: 0;
	}

	.email {
		margin: 1rem 0 0;

		a {
			font-weight: 500;
			text-transform: uppercase;
			color: #000;
		}
	}
}
</style>
