/* eslint-disable max-classes-per-file */

/** Custom error */
export class CustomError extends Error {
	/**
	 * @param {string} message Error message
	 * @param {Object} options Additional error information
	 */
	constructor(message, options) {
		super(message);
		this.name = this.constructor.name;
		this.options = options;
	}
}

export class ServerError extends CustomError {
	constructor(response) {
		super(`Error received from server: ${response.reason}`, response);
	}
}
