import detectEthereumProvider from '@metamask/detect-provider';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { ServerError } from '@/Exceptions';
const Personal = require('web3-eth-personal');

const fetchNonce = walletAddress => {
	const u = new URL(process.env.VUE_APP_NONCE_API, process.env.VUE_APP_API_BASE);

	u.searchParams.append('address', walletAddress);

	return fetch(u, {
		method: 'get',
	}).then(response => {
		return response.json();
	}).then(data => {
		if (typeof data.nonce !== 'string' || data.error) {
			console.error('⛔️ Error requesting nonce');
			throw new ServerError(data);
		} else {
			return data.nonce;
		}
	}).catch(e => {
		console.error('⛔️ Error requesting nonce');
		throw e;
	});
};

const getAccount = async provider => {
	if (provider !== window.ethereum) {
		console.error('Do you have multiple wallets installed?');
	}

	const [accounts, chainID] = await Promise.all([
		provider.request({ method: 'eth_requestAccounts' }),
		provider.request({ method: 'eth_chainId' }),
	]);

	return { accounts, chainID };
};

const signIn = (provider, message, walletAddress) => {
	const web3 = new Personal(provider);

	return new Promise((resolve, reject) => {
		web3.sign(message, walletAddress, (err, signature) => {
			if (err) {
				reject(err);
			} else {
				resolve({ walletAddress, signature, message });
			}
		});
	});
};

const requestSignInToken = (walletAddress, signature) => {
	const u = new URL(process.env.VUE_APP_SIGN_IN_API, process.env.VUE_APP_API_BASE);

	return fetch(u, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ walletAddress, signature }),
	}).then(response => {
		return response.json();
	}).then(data => {
		if (typeof data.token !== 'string' || data.error) {
			throw new ServerError(data);
		} else {
			return data.token;
		}
	}).catch(e => {
		console.error('⛔️ Error requesting sign-in token');
		throw e;
	});
};

export default async ({ getters, dispatch, commit }) => {
	console.info('🎬 signIn');

	if (getters.isAuthenticated === false) {
		commit('SET_SIGN_IN_PENDING_STATE', true);
		const provider = await detectEthereumProvider();

		if (provider) {
			commit('SET_HAS_ETHEREUM_PROVIDER', true);

			try {
				const { accounts } = await getAccount(provider);
				const walletAddress = accounts[0];

				const nonce = await fetchNonce(walletAddress);
				const {signature} = await signIn(provider, nonce, walletAddress);
				const token = await requestSignInToken(walletAddress, signature);

				await signInWithCustomToken(getAuth(), token);

				// Fetch all data. Inventory is created during sign-in, so the 'refreshInventory' action
				// does not need to be called.
				await dispatch('fetchAll');
			} catch (e) {
				commit('SET_SIGN_IN_PENDING_STATE', false);
				console.error(e);

				if (e instanceof ServerError) {
					dispatch('setErrorState', {
						heading: 'Error',
						message: 'Sorry, we were unable to validate your signature. Please refresh and try again.',
					});
				} else if (typeof e.code !== 'number' && e.code !== 4001) {
					dispatch('setErrorState', {
						heading: 'Error',
						message: 'An error was encountered during sign-in. Please refresh and try again.',
					});
				}
			}
		} else {
			commit('SET_SIGN_IN_PENDING_STATE', false);
			commit('SET_HAS_ETHEREUM_PROVIDER', false);
		}
	} else {
		commit('SET_SIGN_IN_PENDING_STATE', true);

		try {
			// Inventory is created during sign-in, but not refresh on subsequent visits
			// so this call forces the inventory to be updated.
			await dispatch('refreshInventory');
		} catch (e) {
			if (e instanceof ServerError) {
				dispatch('setErrorState', {
					heading: 'Error',
					message: 'Sorry, we were unable to retrieve your inventory. Please refresh and try again.',
				});
			} else if (typeof e.code !== 'number' && e.code !== 4001) {
				dispatch('setErrorState', {
					heading: 'Error',
					message: 'An error was encountered. Please refresh and try again.',
				});
			}

			return;
		}

		try {
			// Fetch all data
			await dispatch('fetchAll');
		} catch (e) {
			if (e instanceof ServerError) {
				dispatch('setErrorState', {
					heading: 'Error',
					message: 'Sorry, we were unable to retrieve your data. Please refresh and try again.',
				});
			} else if (typeof e.code !== 'number' && e.code !== 4001) {
				dispatch('setErrorState', {
					heading: 'Error',
					message: 'An error was encountered. Please refresh and try again.',
				});
			}

			return;
		}

		commit('SET_SIGN_IN_PENDING_STATE', false);
	}
};
