import Promise from 'bluebird';
import { getFirestore, setDoc, doc, getDocs, collection, deleteDoc, query, where } from '@firebase/firestore/lite';
import FakeProductStatus from '@/data/FakeProductStatus';

export default async ({ state }, { claims }) => {
	console.info('🎬 updateTestClaims', { claims });
	const firestore = getFirestore();

	// Delete existing fake claims
	const colRef = collection(firestore, 'vbk_dcl_nft_claims');
	const docsToDelete = await getDocs(query(colRef, where('test_created_by', '==', state.user.walletAddress)));

	if (docsToDelete.size) {
		await Promise.map(docsToDelete.docs, doc => deleteDoc(doc.ref));
	}

	// Create claim docs
	await Promise.map(claims, claim => {
		const key = [
			claim.contractAddress,
			claim.itemID,
			claim.issueID,
		].join(':');

		const claimDocRef = doc(firestore, 'vbk_dcl_nft_claims', key);
		const d = {
			contract_address: claim.contractAddress, // eslint-disable-line camelcase
			created_at: new Date(), // eslint-disable-line camelcase
			test_only: true, // eslint-disable-line camelcase
			issue_id: claim.issueID, // eslint-disable-line camelcase
			item_id: claim.itemID, // eslint-disable-line camelcase
			key,
			test_created_by: state.user.walletAddress, // eslint-disable-line camelcase
			last_seen_owner: state.user.walletAddress, // eslint-disable-line camelcase
		};

		if (claim.status === FakeProductStatus.CLAIMED) {
			d.claimed_at = new Date(); // eslint-disable-line camelcase
			d.claimed_by = state.user.walletAddress; // eslint-disable-line camelcase
		} else if (claim.status === FakeProductStatus.UNCLAIMED) {
			d.claimed_at = null; // eslint-disable-line camelcase
			d.claimed_by = null; // eslint-disable-line camelcase
		} else if (claim.status === FakeProductStatus.CLAIMED_SOMEONE_ELSE) {
			d.claimed_at = new Date(); // eslint-disable-line camelcase
			d.claimed_by = '0x0'; // eslint-disable-line camelcase
		}

		return setDoc(claimDocRef, d);
	});
};
