export default {
	isError: false,
	error: null,
	auth: {
		claims: [], // Claims contained within the JWT
		isAuthenticated: null, // Is the user Authenticated with Firebase Auth
		isSigningIn: false,
	},
	isSubmittingClaims: false,
	dismissMixedOwnership: false,
	hasEthereumProvider: null,
	activeClaimKey: null,
	editClaim: false,
	editClaimSuccess: false,
	nfts: null,
	claims: null,
	user: {
		fulfilment: null,
		walletAddress: null,
		data: null,
	},
};
