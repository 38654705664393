<template>
	<ArrowButton :label="label" class="button--primary" :disabled="disabled" @click="$emit('click')" />
</template>

<script>
import ArrowButton from '@/components/ArrowButton';

export default {
	name: 'PrimaryButton',
	props: {
		label: String,
		disabled: Boolean,
	},
	components: {
		ArrowButton,
	},
};
</script>

<style lang="scss">
@use "sass:math";

@include bp(ml) {
	.button--primary {
		font-size: 120px;
		height: 142px;
	}
}

@include bp(l) {
	.button--primary {
		font-size: 180px;
		height: 172px;
	}
}
</style>
