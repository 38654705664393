<template>
	<PrimaryScreen class="screen--success">
		<template v-slot>
			<h1 class="heading">{{ locale.heading }}</h1>
			<p class="leader">{{ locale.body }}</p>
		</template>

		<template v-slot:secondary>
			<p class="contact">{{ locale.contact }}</p>
			<p class="email"><a :href="emailLink">{{ locale.email }}</a></p>
		</template>
	</PrimaryScreen>
</template>

<script>
import PrimaryScreen from '../components/PrimaryScreen.vue';
import Locale from '@/data/Locale';

export default {
	name: 'SuccessScreen',
	components: {
		PrimaryScreen,
	},
	computed: {
		locale() {
			return this.isPhysicalClaim ? Locale.success.physical : Locale.success.utility;
		},
		emailLink() {
			return `mailto:${this.locale.email}?subject=` + encodeURIComponent(this.locale.emailSubject);
		},
	},
};
</script>

<style lang="scss">
.screen--success {
	background: #01F800;

	.contact,
	.email {
		font-size: 24px;
		line-height: 30px;
		margin: 0;
	}

	.contact {
		max-width: 400px;
	}

	.email {
		max-width: 240px;
		margin: 1rem 0 0;

		a {
			font-weight: 500;
			text-transform: uppercase;
			color: #000;
		}
	}
}
</style>
