<template>
	<PrimaryScreen :class="classes">
		<template v-slot:secondary>
			<div v-if="nfts.length" class="cart">
				<table>
					<thead>
						<tr>
							<th>Item</th>
							<th>Qty</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in nfts" :key="index">
							<th class="item">
								<div class="wrap">
									<div class="preview">
										<img :alt="item.nft.product_title" :src="`/assets/products/${item.nft.preview_url}`">
									</div>
									<div class="content">
										<div :title="item.nft.product_title" class="title">{{item.nft.product_title}}</div>
										<div :title="item.nft.product_subtitle" class="subtitle">{{item.nft.product_subtitle}}</div>
									</div>
								</div>
							</th>
							<td class="quantity">
								{{ pad(item.quantity) }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<p v-if="notice" class="notice">
				{{ locale.notice }}
			</p>
		</template>

		<div class="form">
			<h2 class="heading">{{ locale.heading }}</h2>

			<template v-if="isPhysicalClaim">
				<SizeSelect :disabled="submitInProgress" class="control--size" :label="locale.fields.size" v-model="shipping.size" :options="sizes" />
			</template>

			<h3 class="sub-heading">{{ locale.subheading }}</h3>
			<TextField :disabled="submitInProgress" class="control--first-name" :label="locale.fields.firstName" v-model="shipping.firstName" />
			<TextField :disabled="submitInProgress" class="control--last-name" :label="locale.fields.lastName" v-model="shipping.lastName" />

			<template v-if="isPhysicalClaim">
				<TextField :disabled="submitInProgress" class="control--address-1" :label="locale.fields.address1" v-model="shipping.address1" placeholder="(Please note we cannot deliver to PO boxes)" />
				<TextField :disabled="submitInProgress" class="control--address-2" :label="locale.fields.address2" v-model="shipping.address2" placeholder="(optional)" />
				<TextField :disabled="submitInProgress" class="control--city" :label="locale.fields.city" v-model="shipping.city" />
				<SelectField :disabled="submitInProgress" class="control--country" :label="locale.fields.country" v-model="shipping.country" :options="countries" />
				<TextField :disabled="submitInProgress" class="control--postcode" :label="locale.fields.postcode" v-model="shipping.postcode" />
				<TextField :disabled="submitInProgress" class="control--phone" type="tel" :label="locale.fields.phone" v-model="shipping.phone" />
			</template>

			<TextField class="control--email" type="email" :label="locale.fields.email" v-model="shipping.email" />
			<LargeCheckbox class="control--opt-in" :label="locale.fields.consent " v-model="communicationOptIn" />
		</div>

		<template v-slot:bottom>
			<div class="button-container">
				<PrimaryButton :disabled="submitInProgress" v-on:click="submit" label="Submit" />
			</div>
		</template>
	</PrimaryScreen>
</template>

<script>
import PrimaryScreen from '@/components/PrimaryScreen.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import TextField from '@/components/TextField.vue';
import SizeSelect from '@/components/SizeSelect.vue';
import SelectField from '@/components/SelectField.vue';
import LargeCheckbox from '@/components/LargeCheckbox.vue';
import Countries from '@/data/Countries';
import Locale from '@/data/Locale';

export default {
	name: 'EditScreen',
	components: {
		PrimaryScreen,
		PrimaryButton,
		SizeSelect,
		TextField,
		LargeCheckbox,
		SelectField,
	},
	data() {
		return {
			communicationOptIn: this.$store.state.user.data.communication_opt_in,
			shipping: Object.assign({
				size: null,
				firstName: '',
				lastName: '',
				address1: '',
				address2: '',
				city: '',
				country: '',
				postcode: '',
				phone: '',
				email: '',
			}, this.$store.getters.existingShippingData),
		};
	},
	methods: {
		submit() {
			this.$store.dispatch('updateClaim', {
				communicationOptIn: this.communicationOptIn,
				shipping: JSON.parse(JSON.stringify(this.shipping)),
			});
		},
		pad(str) {
			const j = str.toFixed(0);

			return j.length < 3 ? j.padStart(2, '0') : j;
		},
	},
	computed: {
		classes() {
			return {
				'screen--claims': true,
				'screen--physical-claim': this.isPhysicalClaim,
				'screen--utility-claim': !this.isPhysicalClaim,
			};
		},
		sizes() {
			return ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
		},
		submitInProgress() {
			return this.$store.state.isSubmittingClaims === true;
		},
		locale() {
			return this.isPhysicalClaim ? Locale.edit.physical : Locale.edit.utilityOnly;
		},
		isPhysicalClaim() {
			return this.$store.getters.ownership.anyPhysical;
		},
		countries() {
			return Countries;
		},
		nfts() {
			const temp = this.$store.getters.claimsByUser.reduce((temp, claim) => {
				const key = [claim.contract_address, claim.item_id].join(':');

				if (Object.prototype.hasOwnProperty.call(temp, key)) {
					temp[key].quantity += 1;
				} else {
					const nft = Object.values(this.$store.state.nfts).find(nft => {
						return claim.contract_address === nft.contract_address && claim.item_id === nft.item_id;
					});

					if (nft) {
						temp[key] = {
							quantity: 1,
							nft,
						};
					}
				}

				return temp;
			}, {});

			return Object.values(temp);
		},
	},
};
</script>

<style lang="scss">
.screen--claims {
	.notice {
		margin: 35px 0 0;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
	}

	h2 {
		flex: 1 0 100%;
	}

	.content--right {
		display: flex;
		flex-flow: column nowrap;
	}

	.sub-heading {
		flex: 1 1 100%;
		margin: 0;
	}
}

@include bp-max(ml) {
	.screen--claims {
		.content--left { flex: 0 0 auto; }
		.content--right { flex: 1 0 auto; }

		.form {
			display: flex;
			flex-flow: column nowrap;
			gap: 16px;
		}
	}
}

@include bp(ml) {
	.screen--claims {
		.form {
			display: flex;
			flex-flow: row wrap;
			gap: 16px;
		}
		.control--size {
			margin: 0 0 40px;
		}
		.control--size,
		.control--email,
		.control--address-1,
		.control--address-2 {
			flex: 1 1 100%;
		}
		.control--first-name,
		.control--last-name,
		.control--city,
		.control--country,
		.control--postcode,
		.control--phone,
		.control--opt-in {
			flex: 1 1 45%;
		}
		.control--opt-in {
			max-width: 50vw;
		}
	}
}

.cart {
	table {
		table-layout: fixed;
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
	}

	th, td {
		border: solid 1px black;
		padding: 0;

		&:first-child { width: 80%; }
		&:last-child { width: 20%; }
	}

	thead {
		text-transform: uppercase;

		th {
			padding: 4px 9px;
			text-align: left;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}
	}
	tbody {
		th, td {
			text-align: left;
		}
		th {
			width: 100%;
		}

		.item {
			> .wrap {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				gap: 15px;
			}

			.preview {
				flex: 0 0 auto;
				width: 64px;

				img {
					width: 64px;
					height: auto;
					display: block;
				}
			}
			.content {
				overflow: hidden;
				flex: 0 1 auto;
			}
		}

		.title,
		.subtitle {
			width: 100%;
			text-transform: uppercase;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.title {
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
		}
		.subtitle {
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
			letter-spacing: 0.05em;
		}
		.quantity {
			font-family: "Suisse Intl Condensed", sans-serif;
			font-weight: 500;
			font-size: 48px;
			line-height: 48px;
			text-align: center;
			text-transform: uppercase;
		}
	}
}
</style>
