export default [
	{
		name: 'Mars Jacket',
		sub: 'Black Edition',
		contractAddress: '0xFAKE',
		itemID: '0',
	}, {
		name: 'Mars Jacket',
		sub: 'White Edition',
		contractAddress: '0xFAKE',
		itemID: '1',
	}, {
		name: 'Mars Jacket',
		sub: 'Orange Edition',
		contractAddress: '0xFAKE',
		itemID: '2',
	},
];
