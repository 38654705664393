import Promise from 'bluebird';
import { getFirestore, addDoc, updateDoc, doc, collection } from '@firebase/firestore/lite';

export default async ({ state, dispatch, commit, getters }, { communicationOptIn, shipping }) => {
	console.info('🎬 updateClaim', { communicationOptIn, shipping });

	const claimsByUser = Object.values(getters.claimsByUser).map(x => x.key);
	// const allClaims = Object.values(state.claims).map(x => x.key);
	const firestore = getFirestore();

	commit('SET_SUBMITTING_CLAIMS', true);

	try {
		// Update claims for users who are claiming a NFT (not previously claimed)
		const updateClaimsByUser = Promise.map(claimsByUser, key => {
			// claimed_by
			const claimDocRef = doc(firestore, 'vbk_dcl_nft_claims', key);

			return updateDoc(claimDocRef, {
				claimed_at: new Date(), // eslint-disable-line camelcase
				claimed_by: state.user.walletAddress, // eslint-disable-line camelcase
			});
		});

		// communicationOptIn
		const userDocRef = doc(firestore, 'vbk_wallet_user_data', state.user.walletAddress);
		const updateCommunicationOptIn = updateDoc(userDocRef, {
			communication_opt_in: communicationOptIn, // eslint-disable-line camelcase
		});

		// Fulfilment data
		const updateShippingOnClaims = addDoc(collection(userDocRef, 'shipping'), {
			created_at: new Date(), // eslint-disable-line camelcase
			shipping: JSON.stringify(shipping),
			owner: state.user.walletAddress,
		});

		// Update documents
		await Promise.all([
			updateClaimsByUser,
			updateShippingOnClaims,
			updateCommunicationOptIn,
		]);


		// Fetch updated documents (since we're using Firebase Lite, there is not
		// document change listeners/synchronisation to do this automatically)
		await dispatch('fetchAll');

		// No longer edit the claim
		commit('SET_SUBMITTING_CLAIMS', false);
		commit('SET_EDIT_CLAIM_SUCCESS');
	} catch (e) {
		console.error(e);

		dispatch('setErrorState', {
			heading: 'Error',
			message: 'An error was encountered whilst submitting your data. Please refresh and try again.',
		});
	}
};
