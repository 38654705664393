<template>
	<div :class="controlClasses">
		<label class="control__label" :for="`${id}`">
			<div class="floating">{{ label }}</div>
			<div aria-hidden="true" class="placeholder">{{ placeholder }}</div>
		</label>

		<div class="control__field">
			<input
				:id="`${id}`"
				:type="type"
				:value="value"
				:disabled="disabled"
				:placeholder="placeholder"
				@focus="handleFocus"
				@blur="handleBlur"
			/>
		</div>
	</div>
</template>

<script>
import { generatePushID } from '../Utils';

export default {
	name: 'TextField',
	props: {
		label: {
			type: String,
			default: 'Label',
		},
		type: {
			type: String,
			default: 'text',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		value: String,
		placeholder: String,
	},
	model: {
		event: 'blur',
	},
	computed: {
		controlClasses() {
			return {
				'control': true,
				'has-value': this.value.length > 0,
				'has-focus': this.focused,
			};
		},
	},
	data() {
		return {
			id: `field-${generatePushID()}`,
			focused: false,
		};
	},
	methods: {
		handleFocus() {
			this.focused = true;
		},
		handleBlur(e) {
			this.focused = false;
			this.$emit('blur', e.target.value);
		},
	},
};
</script>

<style lang="scss" scoped>
@mixin input-size($height) {
	height: $height;

	&.has-focus,
	&.has-value {
		input {
			$input-top: 18px;
			top: $input-top;
			height: $height - $input-top;
		}
	}
}

.control {
	@include input-size(56px);
	position: relative;
	background: white;

	input {
		display: block;
		width: 100%;
		appearance: none;
		padding: 0 16px 4px;
		border: 0;
		color: black;
		background: transparent;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 1;

		&:focus {
			border: 0;
			outline: 0;
		}

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	&.has-focus,
	&.has-value {
		display: block;

		input {
			opacity: 1;
			padding: 0 16px 10px;
		}

		.control__label {
			top: 14px;
			height: auto;
			font-size: 10px;

			.placeholder {
				opacity: 0;
			}
		}
	}
}

.control__field {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.control__label {
	transition-property: left, top, font-size, transform;
	transition-duration: 200ms;
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translate(0, -50%);
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	padding: 0 16px;
	letter-spacing: 0.05em;
	text-transform: uppercase;

	.floating,
	.placeholder {
		display: inline-block;
	}

	.floating {
		margin: 0 0.5rem 0 0;
	}

	.placeholder {
		opacity: 1;
		transition-property: opacity;
		transition-duration: 200ms;
		font-weight: lighter;
		text-transform: none;
	}
}

@include bp-max(ml) {
	.control__label {
		.placeholder {
			font-size: 11px;
		}
	}
}
</style>
